// define the UserNotifications component
import React, { useState } from "react";

const UserNotifications = () => {
    const [activeButton, setActiveButton] = useState(0);

    return (
        <div className="grid max-w-[90vw] w-[400px]">
            <a
                className="flex gap-2 items-start border-b border-slate-700 py-2 overflow-auto hover:bg-slate-800 cursor-pointer"
                href="/6LrYrViaQHZtuk469MiT2PffW6mf3hQmdNdaPK14R7Gv"
            >
                <div className="text-red-500 mt-1">
                    <svg
                        width={20}
                        height={20}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.35248 4.90532C1.35248 2.94498 2.936 1.35248 4.89346 1.35248C6.25769 1.35248 6.86058 1.92336 7.50002 2.93545C8.13946 1.92336 8.74235 1.35248 10.1066 1.35248C12.064 1.35248 13.6476 2.94498 13.6476 4.90532C13.6476 6.74041 12.6013 8.50508 11.4008 9.96927C10.2636 11.3562 8.92194 12.5508 8.00601 13.3664C7.94645 13.4194 7.88869 13.4709 7.83291 13.5206C7.64324 13.6899 7.3568 13.6899 7.16713 13.5206C7.11135 13.4709 7.05359 13.4194 6.99403 13.3664C6.0781 12.5508 4.73641 11.3562 3.59926 9.96927C2.39872 8.50508 1.35248 6.74041 1.35248 4.90532Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <div>
                    <img
                        alt=""
                        loading="lazy"
                        width={128}
                        height={128}
                        decoding="async"
                        data-nimg={1}
                        className="w-10 h-10 rounded-full object-contain"
                        src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=128&img-dpr=2&img-onerror=redirect"
                        style={{ color: "transparent", display: "block" }}
                    />
                </div>
                <div>
                    <div>935M8g liked your comment</div>
                    <div>#10319453 pamp it</div>
                </div>
            </a>
        </div>

    );
}
export default UserNotifications;