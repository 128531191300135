import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getBaseApiUrl } from "../../../utils/constants";
import { uploadImageToFreeImage } from "../../../utils";
import { useAuth } from "../../../context/AuthContext";
import { useAccount } from "wagmi";
import { notifyError, notifySuccess, notifyWarning } from "../../../hooks/utils";

interface PostReplyProps {
  token: string;
  open: boolean;
  onClose: () => void;
  replyTo?: string;
}

const PostReply: React.FC<PostReplyProps> = ({ token, open, onClose, replyTo = "" }) => {

  const modalRef = useRef<HTMLDivElement>(null);


  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add event listener to detect clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when component is unmounted or `open` changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  const account = useAccount();
  const { isSignedIn } = useAuth() as any;





  const [formData, setFormData] = useState({
    comment: replyTo ? `#${replyTo} ` : "",
    image: null,
  });
  const [errors, setErrors] = useState({} as any);

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleImageChange = (e: any) => {
    setFormData((prevData) => ({
      ...prevData,
      image: e.target.files[0],
    }));
  };

  const validateForm = () => {
    const newErrors = {} as any;
    if (!formData.comment) newErrors.comment = "Comment is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!validateForm()) {
      console.log("Validation errors:", errors);
      return;
    }

    if (!accessToken || !isSignedIn || !account) {
      // Redirect to login or show a message
      notifyWarning("You need to sign in to post a reply");
      return;
    }

    // Upload the image to FreeImage API if an image is selected
    let uploadedImgUrl = null;
    if (formData.image) {
      uploadedImgUrl = await uploadImageToFreeImage(formData.image);
      if (!uploadedImgUrl) {
        notifyError("Failed to upload image. Please try again.");
        return;
      }
    }

    try {
      const response = await axios.post(`${getBaseApiUrl()}/api/post-reply`, {
        user: account.address,
        token: token,
        comment: formData.comment,
        image: uploadedImgUrl || "",
        postedByTrade: false,
        tradeAssociated: null,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      });

      if (response.data.success) {
        notifySuccess("Reply posted successfully.");
      } else {
        notifyError("Error posting reply.");
      }
    } catch (error) {
      console.error("Error posting reply:", error);
      notifyError("An error occurred while trying to post reply.");
    }
  };

  // Determine which error to display
  const getFirstError = () => {
    if (errors.comment) return errors.comment;
    return null;
  };


  // Return null if the modal is not open
  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white p-4 text-center"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "1px solid" }}
      >
        <div className="grid gap-2">
          <label className="mb-1 text-sm font-semibold text-blue-400" htmlFor="text">
            add a comment
          </label>
          <textarea
            className="bg-[#2a2a3b] border border-slate-200 rounded-md p-2 h-24"
            id="comment"
            placeholder="comment"
            value={formData.comment}
                  onChange={handleInputChange}
          />
          <div className="flex flex-col">
            <label
              className="mb-1 text-sm font-semibold text-blue-400"
              htmlFor="image"
            >
              image (optional)
            </label>
            <input
              className="bg-[#2a2a3b] border border-slate-200 rounded-md p-2 w-full"
              id="image"
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </div>
        </div>
        <button className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90 h-10 px-4 bg-green-400 text-primary w-full py-3 rounded-md hover:bg-green-200"
        onClick={handleSubmit}
        >
          post reply
        </button>
        <div className="text-slate-50 hover:font-bold hover:text-slate-50 cursor-pointer w-fit justify-self-center"
        onClick={onClose}>
          [cancel]
        </div>
      </div>
    </div>
  );
}
export default PostReply;