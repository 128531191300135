import axios from "axios";
import { PinataSDK } from "pinata";

export function setCookie(name: any, value: any, days: any) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(name: any) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// do a function that formats numbers into smt like this $70.4M
export const formatNumber = (num: number | null): string => {
    if (num === null) {
        return "-";
    }
    if (num >= 1e6) {
        return `${(num / 1e6).toFixed(2)}M`;
    }
    if (num >= 1e3) {
        return `${(num / 1e3).toFixed(2)}K`;
    }

    return `${num.toFixed(2)}`;
}

// do a function to convert a string to html URL
export const convertToURL = (str: string): string => {
    // replace all spaces with space character, / with special character
    return str.replace(/ /g, "%20").replace("/", "%2F");
}

// do a function to covert from a url to a string
export const convertFromURL = (str: string): string => {
    return str.replace(/%20/g, " ").replace(/%2F/g, "/");
}

// do a function to convert a timestamp to "June 2, 2024 7:40 PM"
export const convertTimestampToDate = (timestamp: number): string => {
    return new Date(timestamp * 1000).toLocaleString();
}

// do a function to conver a number like 13213412.432 to 13,213,412.432
export const formatNumberWithCommas = (num: number): string => {
    // if the number is bigger than 1000 then toFixed(0) and add commas
    if (num >= 1000) {
        num = Number(num.toFixed(0))
    } else {
        num = Number(num.toPrecision(2))
    }
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

// Function to upload image to FreeImage API
export const uploadImageToFreeImage = async (imageFile: File): Promise<string | null> => {
    const pinata = new PinataSDK({
        pinataJwt: process.env.REACT_APP_PINATA_API_KEY,
        pinataGateway: "https://cyan-eligible-octopus-715.mypinata.cloud",
    });

    try {
        const upload = await pinata.upload.file(imageFile);

        if (upload.IpfsHash) {
            const imageUrl = `https://cyan-eligible-octopus-715.mypinata.cloud/ipfs/${upload.IpfsHash}`;
            return imageUrl;
        }
        return null;
    } catch (error) {
        console.error("Error uploading image:", error);
        return null;
    }
};