// define the UserCoinsCreated component
import React, { useState } from "react";
import UserCoinCreatedCard from "./UserCoinCreatedCard";

interface UserCoinsCreatedProps {
  user: any;
}

const UserCoinsCreated: React.FC<UserCoinsCreatedProps> = ({ user }) => {
  const [activeButton, setActiveButton] = useState(0);

  return (
    <div className="max-w-[400px]">
      {(user && user.coinsCreated) && user.coinsCreated.map((coin: any, index: number) => (
        <UserCoinCreatedCard key={coin.address} coin={coin} />
      ))}
      
      <div className="w-full flex justify-center mt-4">
        <div className="justify-self-end mb-20">
          <div className="flex justify-center space-x-2 text-slate-50">
            <button
              disabled
              className="text-sm text-slate-400 cursor-not-allowed"
            >
              [ &lt;&lt; ]
            </button>
            <span>1</span>
            <button
              className="text-sm text-slate-400 cursor-not-allowed"
              disabled
            >
              [ &gt;&gt; ]
            </button>
          </div>
        </div>
      </div>
    </div>

  );
}
export default UserCoinsCreated;