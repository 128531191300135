// create a react hook to get general info
import { useEffect, useState } from "react";
import { TokenHolderInfo } from "../types";
import { SCAN_URL } from "../utils/constants";
import { formatUnits } from "viem";

const useGetTokenHolders = (tokenAddress: string) => {
    const [generalInfo, setGeneralInfo] = useState<TokenHolderInfo[]>();

    useEffect(() => {
        const fetchGeneralInfo = async () => {
            try {
                const totalSupplyFetch = await fetch(
                    `${SCAN_URL}api?module=stats&action=tokensupply&contractaddress=${tokenAddress}`
                );
                const dataTotalSupply = await totalSupplyFetch.json();
                const totalSupply = dataTotalSupply.result / 10 ** 18;
                const response = await fetch(
                    `${SCAN_URL}api?module=token&action=getTokenHolders&contractaddress=${tokenAddress}&page=1&offset=20`
                );
                const data = await response.json();

                const formattedData = data.result.map((item: any) => {
                    return {
                        address: item.address,
                        balance: item.value,
                        percentage: ((Number(formatUnits(item.value, 18))) / totalSupply) * 100,
                        isDev: false,
                        isBondingCurve: false,
                    };
                });

                console.log(formattedData);
                setGeneralInfo(formattedData);
                // debugger;
            } catch (error) {
                console.error(error);
            }
        };
        fetchGeneralInfo();
    }, [tokenAddress]);

    return generalInfo;
};
export default useGetTokenHolders;