export const FACTORY_CONTRACT = "0xfE77CBC976b2D41342B862B0598628AF6cEf1ba1"
export const DEFAULT_USER_PIC = "https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1"

export const SCAN_URL = "https://explorer-testnet.soneium.org/"
export const DEX_NAME = "Sonus Exchange"

// do a export function that checks if im on localhost or not
export function isLocalhost() {
    return window.location.hostname === "localhost"
}

// do other export function to get the thegraph url based on isLocalhost
export function getBaseApiUrl() {
    const forceProd = false
    if (forceProd) {
        return "https://api.pumpstation.meme"
    }
    return isLocalhost() ? "http://localhost:5000" : "https://api.pumpstation.meme"
}