// define the FarmPage component
import React, { useEffect, useState } from "react";
// import "./farmpage.css";
import PageContainer from "../../components/PageContainer/PageContainer";
import TokenCard from "./components/TokenCard";
import ButtonMenu from "../../components/ButtonMenu/ButtonMenu";
import KingOfTheHill from "../../assets/img/king-of-the-hill.webp";
import KingOfTheHillBadge from "../../assets/img/king.webp";
import useAllTokensInfo from "../../hooks/useAllTokensInfo";
import Footer from "../../components/Footer";
import { CreatedTokenInfo } from "../../types";
import useKingOfPump from "../../hooks/useKingOfPump";

const FarmPage = () => {
    const [showAnimations, setShowAnimations] = useState(true);
    const [showNsfw, setShowNsfw] = useState(false);
    const [coinsList, setCoinsList] = useState(1);

    const kingOfPump = useKingOfPump();

    // State to keep track of selected option
    const [sortOptionChosen, setSortOptionChosen] = useState('sort: bump order');

    // Function to handle the change event of the select element
    const handleSortChange = (event: any) => {
        setSortOptionChosen(event.target.value);
    };
    // State to keep track of selected option
    const [orderChosen, setOrderChosen] = useState('order: desc');

    // Function to handle the change event of the select element
    const handleOrderChange = (event: any) => {
        setOrderChosen(event.target.value);
    };

    const allTokens = useAllTokensInfo();

    const [searchInput, setSearchInput] = useState('');
    const [filteredTokens, setFilteredTokens] = useState([] as any);
    
    useEffect(() => {
        if (allTokens) {
            const filtered = allTokens.filter((token) => {
                return showNsfw || token.nsfw === false;
            });
            setFilteredTokens(filtered as any);
        }
    }, [allTokens, showNsfw]);

    // Handle search input change
    const handleSearchChange = (e: any) => {
        const value = e.target.value;
        setSearchInput(value);

        // Filter tokens based on the search input
        const filtered = allTokens?.filter((token) =>
            token.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredTokens(filtered);
    };

    return (
        <PageContainer>
            <main className="h-full" style={{ minHeight: '100vh' }}>
                <div className="grid h-fit md:gap-12 gap-4">
                    <div className="flex flex-col items-center w-full mt-4">
                        <a
                            className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 mb-4 text-2xl text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50"
                            href="/create"
                        >
                            [start a new coin]
                        </a>
                        <div className="text-white max-w-[800px] grid gap-2">
                            <img
                                alt="king of the hill"
                                loading="lazy"
                                width={166}
                                height={32}
                                decoding="async"
                                data-nimg={1}
                                className="h-8 justify-self-center"
                                src={KingOfTheHill}
                                style={{ color: "transparent" }}
                            />

                            {kingOfPump && (
                                <TokenCard key={"kingofpump"} tokenInfo={kingOfPump} />
                            )}
                            {/* <a href="/4yw22YgyLtM5KNYHvWHSE6iZT64Kx4pGcd6m18fcLNJh">
                                <div className="p-2 flex border hover:border-white gap-2 w-full max-h-[300px] overflow-hidden border-transparent">
                                    <div className="min-w-20">
                                        <img
                                            alt="Moosy "
                                            loading="lazy"
                                            width={200}
                                            height={200}
                                            decoding="async"
                                            data-nimg={1}
                                            className="mr-4 w-20 h-auto"
                                            src="https://pump.mypinata.cloud/ipfs/QmenXaeYFjrDpyoudSjqQq54pFTUz5PnD1RaxgVuk6zF4L?img-width=200&img-dpr=2&img-onerror=redirect"
                                            style={{ color: "transparent", display: "block" }}
                                        />
                                    </div>
                                    <div className="gap-1 grid h-fit">
                                        <div className="text-xs text-blue-200 flex items-center gap-2">
                                            <div>Created by</div>
                                            <button
                                            // 
                                            >
                                                <span className="flex gap-1  items-center">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width={16}
                                                        height={16}
                                                        decoding="async"
                                                        data-nimg={1}
                                                        className="w-4 h-4 rounded"
                                                        src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
                                                        style={{ color: "transparent", display: "block" }}
                                                    />
                                                    <span
                                                        className="px-1 rounded hover:underline flex gap-1"
                                                        style={{ backgroundColor: "transparent" }}
                                                    >
                                                        6oafkn{" "}
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="text-xs text-green-300 flex gap-1">
                                            market cap: 4.03K
                                            <div className="flex text-green-500">
                                                [badge:{" "}
                                                <div>
                                                    <div>
                                                        <div
                                                            className="cursor-pointer hover:opacity-7"

                                                            aria-haspopup="dialog"
                                                            aria-expanded="false"
                                                            aria-controls="radix-:r5k:"
                                                            data-state="closed"
                                                        >
                                                            <img
                                                                alt="king of the hill badge"
                                                                loading="lazy"
                                                                width={18}
                                                                height={18}
                                                                decoding="async"
                                                                data-nimg={1}
                                                                src={KingOfTheHillBadge}
                                                                style={{ color: "transparent" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                ]
                                            </div>
                                        </div>
                                        <p className="text-xs flex items-center gap-2">replies: 14</p>
                                        <p className="text-sm w-full font-bold">Moosy [ticker: Moosy ]</p>
                                    </div>
                                </div>
                            </a> */}
                        </div>
                    </div>
                    <div className="grid px-2 sm:p-0 justify-center w-[100vw]">
                        <div
                            className="grid gap-2 w-[90vw] max-w-[450px]"
                        // style={{ gridTemplateColumns: "1fr auto" }}
                        >
                            <input
                                className="nes-input is-dark"
                                placeholder="search for token"
                                type="search"
                                value={searchInput}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    <div className="grid gap-6 md:gap-4 md:px-12 px-2">
                        <div className="grid gap-2 px-2">
                            <div className="flex gap-4">
                                <button className={`grid gap-1 cursor-pointer ${coinsList === 0 ? "text-green-300" : "text-gray-500"}`} onClick={() => setCoinsList(0)}>
                                    Following
                                    {coinsList === 0 && <span className="w-full h-1 bg-green-300 rounded" />}
                                </button>
                                <button className={`grid gap-1 cursor-pointer ${coinsList === 1 ? "text-green-300" : "text-gray-500"}`} onClick={() => setCoinsList(1)}>
                                    Terminal
                                    {coinsList === 1 && <span className="w-full h-1 bg-green-300 rounded" />}
                                </button>
                                <button className={`grid gap-1 cursor-pointer ${coinsList === 2 ? "text-green-300" : "text-gray-500"}`} onClick={() => setCoinsList(2)}>
                                    For You
                                    {coinsList === 2 && <span className="w-full h-1 bg-green-300 rounded" />}
                                </button>
                            </div>
                            <div className="grid sm:flex gap-4 w-full items-center">
                                <div className="flex gap-4">
                                    <div>
                                        <div className="nes-select is-dark">
                                            <select required id="sort_select" onClick={handleSortChange}>
                                                {/* <option value="" disabled selected hidden>Select...</option> */}
                                                <option value="sort: bump order">sort: bump order</option>
                                                <option value="sort: featured">sort: featured</option>
                                                <option value="sort: creation time">sort: creation time</option>
                                                <option value="sort: last reply">sort: last reply</option>
                                                <option value="sort: currently live">sort: currently live</option>
                                                <option value="sort: market cap">sort: market cap</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="nes-select is-dark">
                                            <select required id="order_select" onClick={handleOrderChange}>
                                                {/* <option value="" disabled selected hidden>Select...</option> */}
                                                <option value="order: asc">order: asc</option>
                                                <option value="order: desc">order: desc</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:grid flex flex-wrap text-sm md:gap-1 gap-4">
                                    <div className="flex gap-1 h-fit items-center text-white">
                                        <div>Show animations:</div>
                                        <ButtonMenu active={showAnimations} onClick={() => setShowAnimations(true)}>On</ButtonMenu>
                                        <ButtonMenu active={!showAnimations} onClick={() => setShowAnimations(false)}>Off</ButtonMenu>
                                    </div>
                                    <div className="flex gap-1 h-fit items-center text-white">
                                        <div>Include nsfw:</div>
                                        <ButtonMenu active={showNsfw} onClick={() => setShowNsfw(true)}>On</ButtonMenu>
                                        <ButtonMenu active={!showNsfw} onClick={() => setShowNsfw(false)}>Off</ButtonMenu>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {coinsList === 0 && (<></>)}
                        {coinsList === 1 && (
                            <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 text-gray-400 gap-4">
                                {filteredTokens && filteredTokens.map((tokenInfo: CreatedTokenInfo, index: number) => (
                                    <TokenCard key={index} tokenInfo={tokenInfo} />
                                ))}
                            </div>
                        )}
                        {coinsList === 2 && (<></>)}
                    </div>
                    <div className="w-full flex justify-center mt-4">
                        <div className="justify-self-end mb-20">
                            <div className="flex justify-center space-x-2 text-slate-50">
                                <button
                                    disabled
                                    className="text-sm text-slate-400 cursor-not-allowed"
                                >
                                    [ &lt;&lt; ]
                                </button>
                                <span>1</span>
                                <button className="text-sm text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50">
                                    [ &gt;&gt; ]
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </PageContainer>
    );
}
export default FarmPage;