// create a react hook to get general info
import { useEffect, useState } from "react";
import { CreatedTokenInfo } from "../types";
import { convertToURL } from "../utils";
import { getBaseApiUrl } from "../utils/constants";

const useTokenInfo = (tokenAddress: string) => {
    const [generalInfo, setGeneralInfo] = useState<CreatedTokenInfo | null>(null);

    useEffect(() => {
        const fetchGeneralInfo = async () => {
            try {
                const response = await fetch(
                    `${getBaseApiUrl()}/api/token-info/` + tokenAddress
                );
                const data = await response.json();

                if (!data.success) {
                    console.error("Error fetching token info:", data.message);
                    return null;
                }

                const formattedData = {
                    tokenId: data.data.tokenId,
                    uniV2Pair: data.data.uniV2Pair,
                    liquidityDeployed: data.data.liquidityDeployed,
                    pumpStationPool: data.data.pumpStationPool,
                    name: data.data.name,
                    symbol: data.data.symbol,
                    marketcap: data.data.marketcap,
                    address: data.data.address,
                    creator: data.data.creator,
                    description: data.data.description,
                    livestreaming: data.data.livestreaming,
                    badges: data.data.badges,
                    replies: data.data.replies,
                    replyIds: data.data.replyIds,
                    twitter: data.data.twitter,
                    telegram: data.data.telegram,
                    website: data.data.website,
                    image: data.data.image,
                    nsfw: data.data.nsfw,
                    createdAt: data.data.createdAt,
                };
                setGeneralInfo(formattedData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchGeneralInfo();
    }, [tokenAddress]);

    return generalInfo;
};
export default useTokenInfo;