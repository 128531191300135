import { useEffect, useState } from "react";
import { TokenTrade } from "../types";
import { getBaseApiUrl } from "../utils/constants";

const useGetLatestTrade = (pollingInterval = 1000) => {
    const [generalInfo, setGeneralInfo] = useState<TokenTrade | null>(null);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const fetchGeneralInfo = async () => {
            try {
                const response = await fetch(`${getBaseApiUrl()}/api/latest-trade/`);
                const data = await response.json();

                if (!data.success) {
                    console.error("Error fetching trades:", data.message);
                    return null;
                }

                const formattedData = {
                    user: data.data.user,
                    token: data.data.token,
                    type: data.data.type,
                    amountToken: data.data.amountToken,
                    amountUSD: data.data.amountUSD,
                    amountETH: data.data.amountETH,
                    txHash: data.data.txHash,
                    createdAt: data.data.createdAt,
                };
                setGeneralInfo(formattedData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchGeneralInfo(); // Initial fetch

        // Set up polling interval
        intervalId = setInterval(() => {
            fetchGeneralInfo();
        }, pollingInterval);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [pollingInterval]);

    return generalInfo;
};

export default useGetLatestTrade;
