import React, { useEffect, useRef, useState } from "react";
import { getBaseApiUrl } from "../../../utils/constants";
import { uploadImageToFreeImage } from "../../../utils";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import { useAccount } from "wagmi";
import { notifyError, notifySuccess, notifyWarning } from "../../../hooks/utils";

interface EditProfileProps {
  open: boolean;
  onClose: () => void;
  user: any;
}

const EditProfile: React.FC<EditProfileProps> = ({ user, open, onClose }) => {

  const { isSignedIn } = useAuth() as any;
  const account = useAccount();

  const [formData, setFormData] = useState({
    username: user.username,
    profileImage: user.profileImage,
    biography: user.biography,
  });
  const [errors, setErrors] = useState({} as any);
  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleImageChange = (e: any) => {
    setFormData((prevData) => ({
      ...prevData,
      profileImage: e.target.files[0],
    }));
  };
  const validateForm = () => {
    const newErrors = {} as any;
    if (!formData.username) newErrors.username = "username required";
    if (!formData.profileImage) newErrors.profileImage = "profileImage required";
    if (!formData.biography) newErrors.biography = "biography required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!validateForm()) {
      return;
    }

    if (!accessToken || !isSignedIn || !account) {
      notifyWarning("You need to sign in to create a token");
      return;
    }

    let uploadedImgUrl;
    if (formData.profileImage) {
      uploadedImgUrl = await uploadImageToFreeImage(formData.profileImage);
      if (!uploadedImgUrl) {
        notifyError("Failed to upload image. Please try again.");
        return;
      }
    }

    try {
      const response = await axios.post(`${getBaseApiUrl()}/api/edit-profile`, {
        username: formData.username, 
        profileImage: uploadedImgUrl, 
        biography: formData.biography
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      });

      if (response.data.success) {
        notifySuccess("Edited profile successfully.");
      } else {
        notifyError("An error occurred while trying to edit profile.");
      }
    } catch (error) {
      console.error("Error editing profile:", error);
      notifyError("An error occurred while trying to edit profile.");
    }
  };


  const modalRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add event listener to detect clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when component is unmounted or `open` changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  // Return null if the modal is not open
  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white p-4 text-center"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "1px solid" }}
      >
        <div className="grid gap-2">
          <div className="font-bold">Edit profile</div>
          <div className="flex gap-4">
            <div>Profile photo</div>
            <div className="relative cursor-pointer">
              <img
                alt=""
                loading="lazy"
                width={64}
                height={64}
                decoding="async"
                data-nimg={1}
                className="w-16 h-16 rounded-full border border-slate-500 object-contain"
                src={`${user.profileImage}?img-width=64&img-dpr=2&img-onerror=redirect`}
                style={{ color: "transparent", display: "block" }}
              />
              <svg
                width={15}
                height={15}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute right-[-6px] bottom-[-6px] h-6 w-6"
              >
                <path
                  d="M12.1464 1.14645C12.3417 0.951184 12.6583 0.951184 12.8535 1.14645L14.8535 3.14645C15.0488 3.34171 15.0488 3.65829 14.8535 3.85355L10.9109 7.79618C10.8349 7.87218 10.7471 7.93543 10.651 7.9835L6.72359 9.94721C6.53109 10.0435 6.29861 10.0057 6.14643 9.85355C5.99425 9.70137 5.95652 9.46889 6.05277 9.27639L8.01648 5.34897C8.06455 5.25283 8.1278 5.16507 8.2038 5.08907L12.1464 1.14645ZM12.5 2.20711L8.91091 5.79618L7.87266 7.87267L8.12731 8.12732L10.2038 7.08907L13.7929 3.5L12.5 2.20711ZM9.99998 2L8.99998 3H4.9C4.47171 3 4.18056 3.00039 3.95552 3.01877C3.73631 3.03668 3.62421 3.06915 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3.06915 3.62421 3.03669 3.73631 3.01878 3.95552C3.00039 4.18056 3 4.47171 3 4.9V11.1C3 11.5283 3.00039 11.8194 3.01878 12.0445C3.03669 12.2637 3.06915 12.3758 3.10899 12.454C3.20487 12.6422 3.35785 12.7951 3.54601 12.891C3.62421 12.9309 3.73631 12.9633 3.95552 12.9812C4.18056 12.9996 4.47171 13 4.9 13H11.1C11.5283 13 11.8194 12.9996 12.0445 12.9812C12.2637 12.9633 12.3758 12.9309 12.454 12.891C12.6422 12.7951 12.7951 12.6422 12.891 12.454C12.9309 12.3758 12.9633 12.2637 12.9812 12.0445C12.9996 11.8194 13 11.5283 13 11.1V6.99998L14 5.99998V11.1V11.1207C14 11.5231 14 11.8553 13.9779 12.1259C13.9549 12.407 13.9057 12.6653 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.6653 13.9057 12.407 13.9549 12.1259 13.9779C11.8553 14 11.5231 14 11.1207 14H11.1H4.9H4.87934C4.47686 14 4.14468 14 3.87409 13.9779C3.59304 13.9549 3.33469 13.9057 3.09202 13.782C2.7157 13.5903 2.40973 13.2843 2.21799 12.908C2.09434 12.6653 2.04506 12.407 2.0221 12.1259C1.99999 11.8553 1.99999 11.5231 2 11.1207V11.1206V11.1V4.9V4.87935V4.87932V4.87931C1.99999 4.47685 1.99999 4.14468 2.0221 3.87409C2.04506 3.59304 2.09434 3.33469 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.33469 2.09434 3.59304 2.04506 3.87409 2.0221C4.14468 1.99999 4.47685 1.99999 4.87932 2H4.87935H4.9H9.99998Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </svg>
              {/* <input accept="image/*" type="file" style={{ display: "none" }} /> */}
              <input
                  className="bg-[#2a2a3b] border border-slate-200 rounded-md p-2 w-full"
                  id="profileImage"
                  accept="image/*"
                  type="file"
                  // style={{ display: "none" }}
                  onChange={handleImageChange}
                />
            </div>
          </div>
          <div className="flex gap-4 mt-4">
            <div>Username</div>
            {/* <input
              className="flex h-10 rounded-md border border-slate-200 px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 bg-transparent text-white outline-none w-full pl-3"
              defaultValue="keking"
            /> */}
            <input
                  className="nes-input is-dark"
                  id="username"
                  placeholder=""
                  // type="text"
                  value={formData.username}
                  onChange={handleInputChange}
                />
          </div>
          <div className="flex gap-4 mt-4">
            <div>Bio</div>
            {/* <input
              className="flex h-10 rounded-md border border-slate-200 px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 bg-transparent text-white outline-none w-full pl-3"
              maxLength={250}
              defaultValue=""
            /> */}
            <textarea
                  className="nes-input is-dark"
                  id="biography"
                  placeholder=""
                  maxLength={250}
                  // type="text"
                  value={formData.biography}
                  onChange={handleInputChange}
                />
          </div>
          <div className="flex gap-2 w-fit justify-self-end">
            <div className="text-slate-50 hover:font-bold hover:text-slate-50 cursor-pointer w-fit justify-self-center"
            onClick={onClose}>
              [close]
            </div>
            <button
              // disabled
              className="py-1 px-4 rounded cursor-pointer nes-btn is-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}
export default EditProfile;