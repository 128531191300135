import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

const CandlestickChart = ({ trades }) => {
    const [ohlcData, setOhlcData] = useState([]);
    const [timeframe, setTimeframe] = useState(1); // Default to 1-minute timeframe
    const [loading, setLoading] = useState(true); // Add loading state

    // Function to calculate time range for the x-axis
    const calculateTimeRange = () => {
        const now = dayjs();
        let minTime, maxTime;

        if (timeframe === 1) {
            minTime = now.subtract(30, 'minute'); // Last 30 minutes
            maxTime = now.add(20, 'minute');      // Next 20 minutes
        } else if (timeframe === 5) {
            minTime = now.subtract(1, 'hour');    // Last 1 hour
            maxTime = now.add(30, 'minute');      // Next 30 minutes
        } else if (timeframe === 15) {
            minTime = now.subtract(6, 'hour');    // Last 6 hours
            maxTime = now.add(2, 'hour');         // Next 2 hours
        }

        return [minTime.valueOf(), maxTime.valueOf()];
    };

    // Function to group trades by a given timeframe and calculate OHLC
    const groupTradesByTimeframe = (trades, timeframe) => {
        const grouped = {};
        let lastOHLC = null;

        // Sort trades by timestamp
        const sortedTrades = trades.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        // Group trades by timeframe
        sortedTrades.forEach(trade => {
            const tradeTime = dayjs(trade.createdAt)
                .startOf('minute')
                .subtract(dayjs(trade.createdAt).minute() % timeframe, 'minute')
                .format();
            const price = trade.amountETH / trade.amountToken;

            if (!grouped[tradeTime]) {
                grouped[tradeTime] = {
                    open: lastOHLC ? lastOHLC.close : price, // Use last close for open
                    high: price,
                    low: price,
                    close: price,
                };
            } else {
                grouped[tradeTime].close = price; // Update close price to the latest
                grouped[tradeTime].high = Math.max(grouped[tradeTime].high, price);
                grouped[tradeTime].low = Math.min(grouped[tradeTime].low, price);
            }

            // Update last OHLC
            lastOHLC = { ...grouped[tradeTime] };
        });

        // Ensure continuity in timeframes
        const timeKeys = Object.keys(grouped).map(time => dayjs(time));
        const start = timeKeys[0] || dayjs(); // If no trades, use current time
        const end = dayjs(); // Use current time as end to fill empty candles until now

        // Create OHLC data for every minute (or given timeframe) between start and end
        let currentTime = start;
        const ohlcDataArray = [];

        while (currentTime.isBefore(end) || currentTime.isSame(end)) {
            const timeKey = currentTime.format();

            if (!grouped[timeKey]) {
                // Fill missing timeframes with a "flat" OHLC based on the last close price
                if (lastOHLC) {
                    ohlcDataArray.push({
                        x: new Date(timeKey),
                        y: [lastOHLC.close, lastOHLC.close, lastOHLC.close, lastOHLC.close],
                    });
                }
            } else {
                // Use the available OHLC data
                ohlcDataArray.push({
                    x: new Date(timeKey),
                    y: [grouped[timeKey].open, grouped[timeKey].high, grouped[timeKey].low, grouped[timeKey].close],
                });
                // Update last OHLC
                lastOHLC = { ...grouped[timeKey] };
            }

            // Move to the next timeframe
            currentTime = currentTime.add(timeframe, 'minute');
        }

        return ohlcDataArray;
    };

    // Adjust the y-axis range to ensure visibility for small changes
    const getYAxisRange = (data) => {
        if (data.length === 0) return [0, 0]; // If no data, return empty range
        const prices = data.flatMap((point) => point.y);
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        const padding = (maxPrice - minPrice) * 0.1; // Add 10% padding
        return [minPrice - padding, maxPrice + padding];
    };

    // Recalculate OHLC data when the trades or timeframe changes
    useEffect(() => {
        setLoading(true); // Start loading before processing data
        if (trades.length > 0) {
            const ohlcFormatted = groupTradesByTimeframe(trades, timeframe);
            setOhlcData(ohlcFormatted);
            setLoading(false); // Stop loading once data is set
        } else {
            setLoading(false); // Stop loading if no trades
        }
    }, [trades, timeframe]);

    // Calculate time range for the x-axis
    const [minX, maxX] = calculateTimeRange();

    // Ensure the y-axis range is calculated dynamically
    const [minYAxis, maxYAxis] = getYAxisRange(ohlcData);

    // Dummy placeholder candlestick when loading
    const placeholderSeries = [
        {
            data: [
                {
                    x: new Date(),
                    y: [0, 0, 0, 0], // Placeholder OHLC values
                },
            ],
        },
    ];

    // Chart options and series
    const options = {
        chart: {
            type: 'candlestick',
            height: 350,
            background: '#1f1f1f', // Dark background
            foreColor: '#ffffff', // White text color
        },
        title: {
            text: `Chart (${timeframe}m)`,
            align: 'left',
            style: {
                color: '#ffffff', // White title color
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: '#ffffff', // White font color for x-axis labels
                },
            },
            axisBorder: {
                color: '#777', // Darker border
            },
            axisTicks: {
                color: '#777', // Darker ticks
            },
            crosshairs: {
                show: true, // Show vertical crosshairs
            },
        },
        yaxis: {
            min: minYAxis,
            max: maxYAxis,
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '8px', // Font size for Y-axis tooltip
                },
            },
            labels: {
                style: {
                    colors: '#ffffff', // White font color for y-axis labels
                },
            },
        },
        grid: {
            borderColor: '#444', // Dark grid lines
            xaxis: {
                lines: {
                    show: true, // Enable vertical grid lines
                },
            },
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#00B746', // Green for upward (buy)
                    downward: '#EF403C', // Red for downward (sell)
                },
                wick: {
                    useFillColor: true, // Use same color for wicks as candles
                },
            },
        },
        tooltip: {
            theme: 'dark', // Dark theme for tooltips
            style: {
                fontSize: '8px', // General font size for the tooltip
                fontFamily: 'inherit', // Use the same font as the rest of the page
            },
            x: {
                show: true,
                format: 'dd MMM HH:mm', // Date format for x-axis
                style: {
                    fontSize: '8px', // Font size for x-axis tooltip
                },
            },
            y: {
                formatter: function (val) {
                    return parseFloat(val).toFixed(10); // Customize this for better decimal control
                },
                style: {
                    fontSize: '8px', // Font size for y-axis tooltip
                },
            },
        },
        crosshairs: {
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '8px', // Smaller font size for crosshair tooltip
                },
            },
        },
    };


    const series = loading ? placeholderSeries : [{ data: ohlcData }];

    return (
        <div className=''>
            {/* Timeframe selector */}
            <div>
                <label>Select Timeframe: </label>
                <select
                    value={timeframe}
                    onChange={(e) => setTimeframe(parseInt(e.target.value, 10))}
                >
                    <option value={1}>1 Minute</option>
                    <option value={5}>5 Minutes</option>
                    <option value={15}>15 Minutes</option>
                </select>
            </div>

            {/* Show placeholder or real chart based on loading state */}
            <Chart options={options} series={series} type="candlestick" height={350} />
        </div>
    );
};

export default CandlestickChart;
