// src/App.js
import React, { useState, useEffect } from 'react';
import '@rainbow-me/rainbowkit/styles.css';
import { getCookie } from './utils';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header/Header';
import { config } from './config';
import styled from 'styled-components';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TokenPage from './views/MainPage/components/TokenPage';
import CreateToken from './views/MainPage/components/CreateToken';
import MainPage from './views/MainPage';
import "./App.css";
import { theme, ConfigProvider } from 'antd';
import { TokenProvider } from './contexts/TokenContext';
import { ContractsProvider } from './contexts/ContractsContext';
import ProfilePage from './views/ProfilePage';
import { AuthProvider } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient();

const AppContainer = styled.div`
  text-align: center;
`;

const App = () => {

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <AuthProvider>
            <ConfigProvider
            // theme={{
            //   algorithm: isDarkMode ? theme.darkAlgorithm : theme.lightAlgorithm,
            //   components: {
            //     Select: {
            //       selectorBg: isDarkMode ? "#0d121f" : '#f3f2f8',
            //       colorText: isDarkMode ? 'rgba(255, 255, 255, .8)' : 'rgba(0, 30, 88, 0.88)',
            //     },
            //   },
            // }}
            >
              <AppContainer className='bg-primary'>
                <Header />
                <Router>
                  <Switch>
                    <Route exact path="/" render={() => <Redirect to="/board" />} />
                    <Route path="/board" component={MainPage} />
                    <Route path="/create" component={CreateToken} />
                    <Route path="/profile/:userAddress" component={ProfilePage} />
                    <Route path="/:tokenAddress" component={TokenPage} />
                  </Switch>
                </Router>
                <ToastContainer
                  theme='dark' />
              </AppContainer>
            </ConfigProvider>
          </AuthProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
