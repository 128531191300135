import { useEffect, useState } from "react";
import { TokenTrade } from "../types";
import { getBaseApiUrl } from "../utils/constants";

const useGetTradesByToken = (tokenAddress: string, pollingInterval = 1000) => {
    const [generalInfo, setGeneralInfo] = useState<TokenTrade[] | null>(null);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const fetchGeneralInfo = async () => {
            try {
                const response = await fetch(`${getBaseApiUrl()}/api/trades/` + tokenAddress);
                const data = await response.json();

                if (!data.success) {
                    console.error("Error fetching trades:", data.message);
                    return null;
                }

                const formattedData = data.data.map((trade: any) => ({
                    user: trade.user,
                    token: trade.token,
                    type: trade.type,
                    amountToken: trade.amountToken,
                    amountUSD: trade.amountUSD,
                    amountETH: trade.amountETH,
                    txHash: trade.txHash,
                    createdAt: trade.createdAt,
                }));
                setGeneralInfo(formattedData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchGeneralInfo(); // Initial fetch

        // Set up polling interval
        intervalId = setInterval(() => {
            fetchGeneralInfo();
        }, pollingInterval);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [tokenAddress, pollingInterval]);

    return generalInfo;
};

export default useGetTradesByToken;
