import React, { useEffect, useRef, useState } from "react";
import { DEX_NAME } from "../../../utils/constants";

interface HowItWorksProps {
  open: boolean;
  onClose: () => void;
}

const HowItWorks: React.FC<HowItWorksProps> = ({ open, onClose }) => {

  const modalRef = useRef<HTMLDivElement>(null);


  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add event listener to detect clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when component is unmounted or `open` changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  // Return null if the modal is not open
  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{pointerEvents: "auto"}} data-aria-hidden="true" aria-hidden="true">
    <div
      ref={modalRef}
      role="dialog"
      id="radix-:r4f:"
      aria-describedby="radix-:r4h:"
      aria-labelledby="radix-:r4g:"
      data-state="open"
      className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white p-4 text-center"
      tabIndex={-1}
      style={{ pointerEvents: "auto", border: "1px solid" }}
    >
      <div className="flex flex-col space-y-1.5 sm:text-left text-center">
        <h2
          id="radix-:rm:"
          className="text-lg font-semibold leading-none tracking-tight text-center"
        >
          How It Works
        </h2>
      </div>
      <div>
        PumpStation prevents rugs by making sure that all created tokens are safe. Each coin
        on pump is a <span className="text-green-300 bold">fair-launch</span> with{" "}
        <span className="text-blue-300">no presale</span> and{" "}
        <span className="text-orange-300">no team allocation.</span>
      </div>
      <div className="bg-primary text-white p-4 space-y-4">
        <div className="text-gray-300">step 1: pick a coin that you like</div>
        <div className="text-gray-300">step 2: buy the coin on the bonding curve</div>
        <div className="text-gray-300">
          step 3: sell at any time to lock in your profits or losses
        </div>
        <div className="text-gray-300">
          step 4: when enough people buy on the bonding curve it reaches a market cap
          of $69,420
        </div>
        <div className="text-gray-300">
          step 5: $12k of liquidity is then deposited in {DEX_NAME} and burned
        </div>
      </div>

      <button className="text-slate-50 hover:text-slate-50 cursor-pointer w-fit justify-self-center" onClick={onClose}>
        [I'm ready to pump]
      </button>
    </div>
    </div>
  );
}
export default HowItWorks;