import { useEffect, useState } from "react";
import { TokenTrade } from "../types";
import { getBaseApiUrl } from "../utils/constants";

const useGetLatestTradesByTokens = (tokenAddresses: string[], pollingInterval = 1000) => {
    const [generalInfo, setGeneralInfo] = useState<{ [key: string]: TokenTrade | null }>({});

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const fetchGeneralInfo = async () => {
            try {
                if (tokenAddresses.length === 0) {
                    console.error("No token addresses provided.");
                    return;
                }

                // Convert the token addresses into a comma-separated string
                const tokenAddressesParam = tokenAddresses.join(',');

                // Fetch the latest trades for the token addresses
                const response = await fetch(`${getBaseApiUrl()}/api/trades/latest/${tokenAddressesParam}`);
                const data = await response.json();

                if (!data.success) {
                    console.error("Error fetching trades:", data.message);
                    return null;
                }

                // Map the trades to the token addresses
                const formattedData = data.data.reduce((acc: { [key: string]: TokenTrade | null }, tradeData: any) => {
                    const { tokenAddress, data: trade } = tradeData;
                    acc[tokenAddress] = trade ? {
                        user: trade.user,
                        token: trade.token,
                        type: trade.type,
                        amountToken: trade.amountToken,
                        amountUSD: trade.amountUSD,
                        amountETH: trade.amountETH,
                        txHash: trade.txHash,
                        createdAt: trade.createdAt,
                    } : null;
                    return acc;
                }, {});

                setGeneralInfo(formattedData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchGeneralInfo(); // Initial fetch

        // Set up polling interval
        intervalId = setInterval(() => {
            fetchGeneralInfo();
        }, pollingInterval);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [tokenAddresses, pollingInterval]);

    return generalInfo;
};

export default useGetLatestTradesByTokens;
