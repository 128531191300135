import { http, createConfig } from 'wagmi'
// import { base, optimism } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect, coinbaseWallet } from 'wagmi/connectors'

const projectId = '<WALLETCONNECT_PROJECT_ID>'


export const soneiumTestnet = {
  id: 1946,
  name: 'Soneium Testnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.minato.soneium.org'] },
  },
  blockExplorers: {
    default: { name: 'Soneiumscan', url: 'https://explorer-testnet.soneium.org/' },
  },
}

export const config = createConfig({
  chains: [soneiumTestnet],
  connectors: [
    injected(),
    // walletConnect({ projectId }),
    coinbaseWallet({
      appName: 'PumpStation',
      appLogoUrl: 'https://pampmybags.finance/logo.png', 
    }),
    metaMask(),
    safe(),
  ],
  transports: {
    // [optimism.id]: http(),
    [soneiumTestnet.id]: http(),
  },
})