import React from "react";
import { CreatedTokenInfo } from "../../../types";
import PumpStationPoolABI from "../../../abis/PumpStationPool.abi.json";
import { useReadContract } from "wagmi";
import { formatUnits, zeroAddress } from "viem";

interface TokenCardProps {
    tokenInfo: CreatedTokenInfo;
}

const TokenCard: React.FC<TokenCardProps> = ({
    tokenInfo
}) => {

    const { data: tokenMarketCap } = useReadContract({
        abi: PumpStationPoolABI,
        address: tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress,
        functionName: 'getCurrentCap',
        args: [],
      });

    return (
        <a href={`/${tokenInfo.address}`}>
            <div className="max-h-[300px] overflow-hidden h-fit p-2 flex gap-2 w-full nes-container is-dark is-rounded" style={{minHeight: '100%'}}>
                <div className="min-w-32">
                    <img
                        alt={tokenInfo.symbol}
                        loading="lazy"
                        width={128}
                        height={128}
                        decoding="async"
                        data-nimg={1}
                        className="mr-4 w-32 h-auto"
                        src={`${tokenInfo.image}?img-width=128&img-dpr=2&img-onerror=redirect`}
                        style={{ color: "transparent", display: "block" }}
                    />
                </div>
                <div className="gap-1 grid h-fit">
                    <div className="text-xs text-blue-200 flex items-center gap-2">
                        {tokenInfo.livestreaming && (
                            <div className="w-fit px-1 bg-green-300 text-green-800 text-xs font-bold rounded dark:bg-red-900 dark:text-red-300">
                                Currently live streaming!
                            </div>
                        )}
                        <div>Created by</div>
                        <button >
                            <span className="flex gap-1  items-center">
                                <img
                                    alt=""
                                    loading="lazy"
                                    width={16}
                                    height={16}
                                    decoding="async"
                                    data-nimg={1}
                                    className="w-4 h-4 rounded"
                                    src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
                                    style={{ color: "transparent", display: "block" }}
                                />
                                <span
                                    className="px-1 rounded hover:underline flex gap-1"
                                    style={{ backgroundColor: "transparent" }}
                                >
                                    {tokenInfo.creator.address.substring(0, 6)}{" "}
                                </span>
                            </span>
                        </button>
                    </div>
                    <div className="text-xs text-green-300 flex gap-1">
                        market cap: {Number(formatUnits(tokenMarketCap as any || "0", 18)).toFixed(2)}
                        {tokenInfo.badges?.map((badge) => (
                            <div className="flex text-green-500">
                                [badge:{" "}
                                <div>
                                    <div>
                                        <div
                                            className="cursor-pointer hover:opacity-7"

                                            aria-haspopup="dialog"
                                            aria-expanded="false"
                                            aria-controls="radix-:r5m:"
                                            data-state="closed"
                                        >
                                            <img
                                                alt="king of the hill badge"
                                                loading="lazy"
                                                width={18}
                                                height={18}
                                                decoding="async"
                                                data-nimg={1}
                                                srcSet="/_next/image?url=%2Fking.png&w=32&q=75 1x, /_next/image?url=%2Fking.png&w=48&q=75 2x"
                                                src="/_next/image?url=%2Fking.png&w=48&q=75"
                                                style={{ color: "transparent" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                ]
                            </div>
                        ))}
                    </div>
                    <p className="text-xs flex items-center gap-2">replies: {tokenInfo.replies}</p>
                    <p
                        className="w-full"
                        style={{ overflowWrap: "break-word", wordBreak: "break-all" }}
                    >
                        <span className="font-bold">{tokenInfo.name} (ticker: {tokenInfo.symbol}): </span>{tokenInfo.description}
                    </p>
                </div>
            </div>
        </a>
    );
}
export default TokenCard;