import React, { useEffect, useRef, useState } from "react";

interface BubbleMapsProps {
  tokenAddress: string;
  open: boolean;
  onClose: () => void;
}

const BubbleMaps: React.FC<BubbleMapsProps> = ({ tokenAddress, open, onClose }) => {

  const modalRef = useRef<HTMLDivElement>(null);

  const [showFull, setShowFull] = useState(false);

  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add event listener to detect clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when component is unmounted or `open` changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  // Return null if the modal is not open
  if (!open) return null;
  
  return (
    <div
    ref={modalRef}
      role="dialog"
      id="radix-:r4f:"
      aria-describedby="radix-:r4h:"
      aria-labelledby="radix-:r4g:"
      data-state="open"
      className=
      {showFull ? "fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white p-4 text-center max-w-full h-full" : "fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white p-4 text-center max-w-[960px]"}
      tabIndex={-1}
      style={{ pointerEvents: "auto" }}
    >
      <div className="flex flex-col space-y-1.5 sm:text-left text-center">
        <div className="flex gap-3 self-end">
          <button className="text-slate-50 hover:text-slate-50 cursor-pointer w-fit justify-self-center" onClick={() => setShowFull(!showFull)}>
            {showFull ? "[Compact]" : "[Full]"}
          </button>
          <button className="text-slate-50 hover:text-slate-50 cursor-pointer w-fit justify-self-center" onClick={onClose}>
            [close]
          </button>
        </div>
        <h2
          id="radix-:rm:"
          className="text-lg font-semibold leading-none tracking-tight text-center"
        >
          Bubble Map
        </h2>
      </div>
      <iframe src={`https://app.bubblemaps.io/sol/token/${tokenAddress}?pumpfun=true&amp;mode=0&amp;hide_context`} width="100%" height="500px" className="h-[80vh]"></iframe>
    </div>
  );
}
export default BubbleMaps;