// define the UserReplies component
import React, { useState } from "react";

const UserReplies = () => {
    const [activeButton, setActiveButton] = useState(0);

    return (
        <div className="grid gap-2 max-w-[420px]">
            <div>(only you can view your replies)</div>
            <div
                id="p10319591"
                className="bg-[#2e303a] p-1 text-slate-200 text-sm grid gap-1 overflow-auto"
            >
                <div className="flex gap-2 text-slate-400 text-xs">
                    <a
                        className="px-1 rounded text-black hover:underline"
                        href="/profile/9UQn11oxa9KwXmzmY2wRhtNRiMZXswipzBouZ6GCLQjD"
                        style={{ backgroundColor: "rgb(216, 253, 151)" }}
                    >
                        9UQn11
                    </a>
                    <div>30/08/2024, 16:21:03</div>
                    <div>#10319591</div>
                    <div className="flex gap-1 text-red-500">
                        1
                        <svg
                            width={16}
                            height={16}
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.35248 4.90532C1.35248 2.94498 2.936 1.35248 4.89346 1.35248C6.25769 1.35248 6.86058 1.92336 7.50002 2.93545C8.13946 1.92336 8.74235 1.35248 10.1066 1.35248C12.064 1.35248 13.6476 2.94498 13.6476 4.90532C13.6476 6.74041 12.6013 8.50508 11.4008 9.96927C10.2636 11.3562 8.92194 12.5508 8.00601 13.3664C7.94645 13.4194 7.88869 13.4709 7.83291 13.5206C7.64324 13.6899 7.3568 13.6899 7.16713 13.5206C7.11135 13.4709 7.05359 13.4194 6.99403 13.3664C6.0781 12.5508 4.73641 11.3562 3.59926 9.96927C2.39872 8.50508 1.35248 6.74041 1.35248 4.90532Z"
                                fill="currentColor"
                                fillRule="evenodd"
                                clipRule="evenodd"
                            />
                        </svg>
                    </div>
                    <a
                        className="text-green-300 w-fit hover:underline"
                        href="/6LrYrViaQHZtuk469MiT2PffW6mf3hQmdNdaPK14R7Gv"
                    >
                        [View thread]
                    </a>
                </div>
                <div className="flex gap-2 items-start">
                    <div>
                        <span className="text-green-300 font-bold">#10319453</span> pamp it
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center mt-4">
                <div className="justify-self-end mb-20">
                    <div className="flex justify-center space-x-2 text-slate-50">
                        <button
                            // disabled=""
                            className="text-sm text-slate-400 cursor-not-allowed"
                        >
                            [ &lt;&lt; ]
                        </button>
                        <span>1</span>
                        <button
                            className="text-sm text-slate-400 cursor-not-allowed"
                        // disabled=""
                        >
                            [ &gt;&gt; ]
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default UserReplies;