import React, { useState } from "react";

interface ButtonMenuProps {
  active: boolean;
  onClick: () => void;
  // text: string;
  children: React.ReactNode;
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({ onClick, active, children} ) => {
  return (
    <div onClick={onClick} className={`cursor-pointer px-1 py-1 rounded ${active ? "bg-green-300 text-black" : "hover:bg-gray-800 text-gray-500"}`}>
      {children}
    </div>
  );
}
export default ButtonMenu;