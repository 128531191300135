// define the UserFollowing component
import React, { useState } from "react";
import { DEFAULT_USER_PIC } from "../../../utils/constants";

interface UserFollowingProps {
    user: any;
}

const UserFollowing: React.FC<UserFollowingProps> = ({ user }) => {
    const [activeButton, setActiveButton] = useState(0);

    return (
        <div className="w-fit">
            {user && user.following.map((following: any) => (
                <div className="flex gap-2 justify-between w-full">
                    <a href={`/profile/${following.username}`}>
                        <span className="flex gap-1  items-center">
                            <img
                                alt=""
                                loading="lazy"
                                width={16}
                                height={16}
                                decoding="async"
                                data-nimg={1}
                                className="rounded w-4 h-4"
                                src={`${following.profileImage !== "" ? following.profileImage : DEFAULT_USER_PIC }?img-width=16&img-dpr=2&img-onerror=redirect`}
                                style={{ color: "transparent", display: "block" }}
                            />
                            <span
                                className="px-1 rounded hover:underline flex gap-1"
                                style={{ backgroundColor: "transparent" }}
                            >
                                {following.username.length === 42 ? following.username.substring(0, 6) : following.username}{" "}
                            </span>
                        </span>
                    </a>
                    <div className="text-slate-400" style={{alignContent: 'center'}}>{following.followers.length} followers</div>
                </div>
            ))}
        </div>
    );
}
export default UserFollowing;