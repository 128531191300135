import React, { useEffect, useState } from "react";

interface Props {
    children: React.ReactNode;
    isHome?: boolean;
}

const PageContainer: React.FC<Props> = ({ children, isHome = false }) => {
    return (
        <div className={`__className_aaf875 bg-primary ${isHome ? "page-content-home" : ""}`}>
            {children}
        </div>
    );
};

export default PageContainer;
