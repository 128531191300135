// define the ProfilePage component
import React, { useEffect, useState } from "react";
import axios from "axios";
import PageContainer from "../../components/PageContainer/PageContainer";
import ButtonMenu from "../../components/ButtonMenu/ButtonMenu";
import UserCoinsHeld from "./components/UserCoinsHeld";
import UserCoinsCreated from "./components/UserCoinsCreated";
import UserFollowers from "./components/UserFollowers";
import UserFollowing from "./components/UserFollowing";
import UserNotifications from "./components/UserNotifications";
import UserReplies from "./components/UserReplies";
import EditProfile from "./components/EditProfile";
import { useAuth } from "../../context/AuthContext";
import { useAccount } from "wagmi";
import { DEFAULT_USER_PIC, getBaseApiUrl, SCAN_URL } from "../../utils/constants";
import Footer from "../../components/Footer";
import { notifyError, notifySuccess, notifyWarning } from "../../hooks/utils";

const ProfilePage = () => {
  const path = window.location.pathname;
  const userIdentifier = path.split('/').pop(); // Assumes last part of pathname is the user identifier

  const [activeButton, setActiveButton] = useState(0);

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  const { isSignedIn } = useAuth() as any; // Replace with actual hook data
  const { address: currentUserAddress } = useAccount(); // Replace with actual hook data

  const [profileUser, setProfileUser] = useState(null) as any;
  const isOwnProfile = profileUser !== null ? currentUserAddress === profileUser.address : false;

  useEffect(() => {
    const fetchUserData = async () => {


      try {
        const response = await axios.get(`${getBaseApiUrl()}/api/user-info/${userIdentifier}`);
        if (response.data.success) {
          setProfileUser(response.data.user);
          // Check if the current user is following the profile user
          if (response.data.user.followers.includes(currentUserAddress)) {
            setIsFollowing(true);
          } else {
            setIsFollowing(false);
          }
        } else {
          notifyError("User not found.");
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [path, currentUserAddress]);

  const handleFollow = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!accessToken || !isSignedIn || !profileUser || !currentUserAddress) {
      // Redirect to login or show a message
      notifyWarning("You need to sign in to follow users.");
      return;
    }

    try {
      const response = await axios.post(`${getBaseApiUrl()}/api/follow`, {
        currentUserId: currentUserAddress,
        profileUserId: profileUser.address
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      });

      if (response.data.success) {
        notifySuccess(response.data.message)
        setIsFollowing(!isFollowing);
      } else {
        notifyError("An error occurred while trying to follow the user.");
      }
    } catch (error) {
      console.error("Error following user:", error);
      notifyError("An error occurred while trying to follow the user.");
    }
  };

  // profileUser === null ? return profile not found


  return (
    <PageContainer>
      {showEditProfile && (
        <EditProfile
          user={profileUser}
          open={showEditProfile}
          onClose={() => setShowEditProfile(false)}
        />
      )}
      <main className="h-full" style={{ minHeight: '100vh' }}>
        <div className=" min-h-[calc(100vh-56px)] flex flex-col ">
          <div className="grid gap-4 text-white mt-8 p-4 justify-items-center items-start">
            <div className="grid gap-1 text-xs sm:text-base">
              <div className="flex gap-4 items-start w-full">
                <div>
                  <img
                    alt=""
                    loading="lazy"
                    width={128}
                    height={128}
                    decoding="async"
                    data-nimg={1}
                    className="w-16 h-16 rounded-full object-contain"
                    src={`${profileUser ? profileUser.profileImage !== "" ? profileUser.profileImage : DEFAULT_USER_PIC : DEFAULT_USER_PIC}?img-width=128&img-dpr=2&img-onerror=redirect`}
                    style={{ color: "transparent", display: "block" }}
                  />
                </div>
                <div className="grid justify-items-start gap-1 w-full">
                  <div className="flex justify-between w-full align-end">
                    <div>
                      <div>@{profileUser ? profileUser.username !== profileUser.address ? profileUser.username : userIdentifier?.substring(0, 6) : userIdentifier?.substring(0, 6)}</div>
                      <div className="flex gap-2 text-sm items-center">
                        <div>{profileUser ? profileUser.followers.length : "0"} followers</div>
                      </div>
                    </div>
                    {!isOwnProfile && (
                      <button className="nes-btn is-success"
                        onClick={handleFollow}>
                        {isFollowing ? "Unfollow" : "Follow"}
                      </button>
                    )}
                    {isOwnProfile && (
                    <button
                      className="flex gap-2 items-center nes-btn is-primary text-sm"
                      type="button"
                      onClick={() => setShowEditProfile(true)}
                    >
                      Edit profile{" "}
                      <svg
                        width={15}
                        height={15}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.1464 1.14645C12.3417 0.951184 12.6583 0.951184 12.8535 1.14645L14.8535 3.14645C15.0488 3.34171 15.0488 3.65829 14.8535 3.85355L10.9109 7.79618C10.8349 7.87218 10.7471 7.93543 10.651 7.9835L6.72359 9.94721C6.53109 10.0435 6.29861 10.0057 6.14643 9.85355C5.99425 9.70137 5.95652 9.46889 6.05277 9.27639L8.01648 5.34897C8.06455 5.25283 8.1278 5.16507 8.2038 5.08907L12.1464 1.14645ZM12.5 2.20711L8.91091 5.79618L7.87266 7.87267L8.12731 8.12732L10.2038 7.08907L13.7929 3.5L12.5 2.20711ZM9.99998 2L8.99998 3H4.9C4.47171 3 4.18056 3.00039 3.95552 3.01877C3.73631 3.03668 3.62421 3.06915 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3.06915 3.62421 3.03669 3.73631 3.01878 3.95552C3.00039 4.18056 3 4.47171 3 4.9V11.1C3 11.5283 3.00039 11.8194 3.01878 12.0445C3.03669 12.2637 3.06915 12.3758 3.10899 12.454C3.20487 12.6422 3.35785 12.7951 3.54601 12.891C3.62421 12.9309 3.73631 12.9633 3.95552 12.9812C4.18056 12.9996 4.47171 13 4.9 13H11.1C11.5283 13 11.8194 12.9996 12.0445 12.9812C12.2637 12.9633 12.3758 12.9309 12.454 12.891C12.6422 12.7951 12.7951 12.6422 12.891 12.454C12.9309 12.3758 12.9633 12.2637 12.9812 12.0445C12.9996 11.8194 13 11.5283 13 11.1V6.99998L14 5.99998V11.1V11.1207C14 11.5231 14 11.8553 13.9779 12.1259C13.9549 12.407 13.9057 12.6653 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.6653 13.9057 12.407 13.9549 12.1259 13.9779C11.8553 14 11.5231 14 11.1207 14H11.1H4.9H4.87934C4.47686 14 4.14468 14 3.87409 13.9779C3.59304 13.9549 3.33469 13.9057 3.09202 13.782C2.7157 13.5903 2.40973 13.2843 2.21799 12.908C2.09434 12.6653 2.04506 12.407 2.0221 12.1259C1.99999 11.8553 1.99999 11.5231 2 11.1207V11.1206V11.1V4.9V4.87935V4.87932V4.87931C1.99999 4.47685 1.99999 4.14468 2.0221 3.87409C2.04506 3.59304 2.09434 3.33469 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.33469 2.09434 3.59304 2.04506 3.87409 2.0221C4.14468 1.99999 4.47685 1.99999 4.87932 2H4.87935H4.9H9.99998Z"
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  )}
                  </div>
                  <div className="flex gap-2 justify-self-start justify-items-start">
                    <div className="flex gap-1 text-red-500 items-center text-xs">
                      Likes received: {profileUser ? profileUser.totalLikesReceived : 0}
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.35248 4.90532C1.35248 2.94498 2.936 1.35248 4.89346 1.35248C6.25769 1.35248 6.86058 1.92336 7.50002 2.93545C8.13946 1.92336 8.74235 1.35248 10.1066 1.35248C12.064 1.35248 13.6476 2.94498 13.6476 4.90532C13.6476 6.74041 12.6013 8.50508 11.4008 9.96927C10.2636 11.3562 8.92194 12.5508 8.00601 13.3664C7.94645 13.4194 7.88869 13.4709 7.83291 13.5206C7.64324 13.6899 7.3568 13.6899 7.16713 13.5206C7.11135 13.4709 7.05359 13.4194 6.99403 13.3664C6.0781 12.5508 4.73641 11.3562 3.59926 9.96927C2.39872 8.50508 1.35248 6.74041 1.35248 4.90532Z"
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="flex gap-1 text-green-300 items-center text-xs">
                      Mentions received: {profileUser ? profileUser.totalMentionsReceived : 0}
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5 3L2.5 3.00002C1.67157 3.00002 1 3.6716 1 4.50002V9.50003C1 10.3285 1.67157 11 2.5 11H7.50003C7.63264 11 7.75982 11.0527 7.85358 11.1465L10 13.2929V11.5C10 11.2239 10.2239 11 10.5 11H12.5C13.3284 11 14 10.3285 14 9.50003V4.5C14 3.67157 13.3284 3 12.5 3ZM2.49999 2.00002L12.5 2C13.8807 2 15 3.11929 15 4.5V9.50003C15 10.8807 13.8807 12 12.5 12H11V14.5C11 14.7022 10.8782 14.8845 10.6913 14.9619C10.5045 15.0393 10.2894 14.9965 10.1464 14.8536L7.29292 12H2.5C1.11929 12 0 10.8807 0 9.50003V4.50002C0 3.11931 1.11928 2.00003 2.49999 2.00002Z"
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-sm border border-slate-600 rounded p-2" style={{ border: "1px solid rgb(71 85 105)" }}>
                {profileUser ? profileUser.address : userIdentifier}
              </div>
              <a
                className="hover:underline text-xs justify-self-end w-fit"
                href={`${SCAN_URL}/address/${userIdentifier}`}
                target="_blank"
                rel="noreferrer"
              >
                View on explorer ↗
              </a>
            </div>
            <div className="flex gap-1 h-fit items-center text-white flex-wrap">
              <ButtonMenu active={activeButton === 0} onClick={() => setActiveButton(0)}>coins held</ButtonMenu>
              {isOwnProfile && (
                <>
                  <ButtonMenu active={activeButton === 4} onClick={() => setActiveButton(4)}>replies</ButtonMenu>
                  <ButtonMenu active={activeButton === 5} onClick={() => setActiveButton(5)}>notifications</ButtonMenu>
                </>
              )}
              <ButtonMenu active={activeButton === 1} onClick={() => setActiveButton(1)}>coins created</ButtonMenu>
              <ButtonMenu active={activeButton === 2} onClick={() => setActiveButton(2)}>followers</ButtonMenu>
              <ButtonMenu active={activeButton === 3} onClick={() => setActiveButton(3)}>following</ButtonMenu>
            </div>
            {activeButton === 0 && (
              <UserCoinsHeld user={profileUser} />
            )}
            {activeButton === 1 && (
              <UserCoinsCreated
                user={profileUser}
              />
            )}
            {activeButton === 2 && (
              <UserFollowers
                user={profileUser}
              />
            )}
            {activeButton === 3 && (
              <UserFollowing
                user={profileUser}
              />
            )}
            {activeButton === 4 && (
              <UserReplies />
            )}
            {activeButton === 5 && (
              <UserNotifications />
            )}
          </div>
        </div>
      </main>
      <Footer />


    </PageContainer>
  );
}
export default ProfilePage;