// create a react hook to get general info
import { useEffect, useState } from "react";
import { CreatedTokenInfo } from "../types";
import { convertToURL } from "../utils";
import { getBaseApiUrl } from "../utils/constants";

const useAllTokensInfo = (pollingInterval = 1000) => {
    const [generalInfo, setGeneralInfo] = useState<CreatedTokenInfo[] | null>(null);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const fetchGeneralInfo = async () => {
            try {
                const response = await fetch(
                    `${getBaseApiUrl()}/api/tokens/`
                );
                const data = await response.json();

                if (!data.success) {
                    console.error("Error fetching tokens:", data.message);
                    return null;
                }

                // Map over the returned token data and format each token
                const formattedData = data.data.map((token: any) => ({
                    name: token.name,
                    symbol: token.symbol,
                    marketcap: token.marketcap,
                    tokenId: token.tokenId,
                    uniV2Pair: token.uniV2Pair,
                    liquidityDeployed: token.liquidityDeployed,
                    pumpStationPool: token.pumpStationPool,
                    address: token.address,
                    creator: token.creator,
                    description: token.description,
                    livestreaming: token.livestreaming,
                    badges: token.badges,
                    replies: token.replies,
                    replyIds: token.replyIds,
                    twitter: token.twitter,
                    telegram: token.telegram,
                    website: token.website,
                    image: token.image,
                    nsfw: token.nsfw,
                    createdAt: token.createdAt,
                }));

                setGeneralInfo(formattedData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchGeneralInfo();
        // Set up polling interval
        intervalId = setInterval(() => {
            fetchGeneralInfo();
        }, pollingInterval);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [pollingInterval]);

    return generalInfo;
};
export default useAllTokensInfo;